<script setup lang="ts">
import { PickupLocationPrompt } from "#components";

const isOpen = ref(false);
const isLoading = ref(false);

const { selectedShippingMethod, setShippingMethod } = useCheckout();

const { apiClient } = useShopwareContext();

const { showDrawer } = useDrawer();

const { data: shippingMethods } = useLazyAsyncData("shipping-methods-all", async () => {
	const {
		data: { elements: shippingMethods }
	} = await apiClient.invoke("readShippingMethod post /shipping-method", {
		query: {
			onlyAvailable: false
		}
	});
	return shippingMethods;
});

const open = () => {
	isOpen.value = true;
};

defineExpose({
	open
});

const close = () => {
	isOpen.value = false;
};

const openPickupLocationSelector = () => {
	showDrawer(PickupLocationPrompt, {
		drawerTitle: "Selecteer je afhaallocatie"
	});
};
const { onAddShippingInfo } = useGtagEvents();

const saveShippingMethodInfo = async (id: string) => {
	await setShippingMethod({ id: id as string });
	onAddShippingInfo();
};
</script>
<template>
	<div>
		<KippieSidebar v-model="isOpen" placement="right" color="white">
			<template #top>
				<div>
					<Typography variant="h2">Bezorgen of afhalen?</Typography>
				</div>
			</template>
			<div class="flex flex-col gap-4">
				<KippieCard
					v-for="method in shippingMethods"
					:key="method.id"
					tabindex="0"
					class="cursor-pointer p-6"
					:class="{
						'border border-brown': selectedShippingMethod?.id !== method.id
					}"
					@click="saveShippingMethodInfo(method.id)"
				>
					<template #icon>
						<SvgIcon v-if="method.technicalName === 'delivery'" name="delivery" class="w-[24px]" />
						<SvgIcon v-else name="store" class="w-[24px]" />
					</template>

					<div class="flex flex-col">
						<span class="font-bold">
							{{ method.name }}
						</span>
						<span class="text-sm">{{ method.description }}</span>
					</div>
				</KippieCard>
				<div v-if="selectedShippingMethod?.id">
					<KippieButton
						v-if="selectedShippingMethod?.technicalName === 'pickup'"
						@click="
							close();
							openPickupLocationSelector();
						"
						size="small"
						class="w-full"
						:disabled="isLoading"
					>
						<span> Winkel kiezen </span>
					</KippieButton>
					<KippieButton v-else @click="close" size="small" class="w-full" :disabled="isLoading">
						<span> Verder winkelen </span>
					</KippieButton>
				</div>
			</div>
		</KippieSidebar>
	</div>
</template>
